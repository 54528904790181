module.exports = {
    name: {
        type: 'translatable-text',
        meta: {
          default_lang: 'vi'
        },
        value: '',
        translations: [
          {
            language: 'vi',
            value: ''
          }
        ]
    },
    thumbnail: null,
    store_id: null,
    type: null,
}
import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/earns/admin/iap-products', payload)
    return response || null
  },
  
  async update(payload) {
    let response = await api.put('/earns/admin/iap-products', payload)
    return response
  },

  async delete(payload) {
    const response = await api.delete('/earns/admin/iap-products', {
      data: payload,
    })
    return response
  },

  async getList(params) {
    let response = await api.get('/earns/admin/iap-products', {
      params,
    })
    return response.data.data
  },

  async get(params) {
    let response = await api.get('/earns/admin/iap-products/detail', {
      params,
    })
    return response.data.data.document || null
  },

}
